$(document).ready(initPage);

function initPage() {
  function checkScroll() {
    if ($(window).scrollTop() > 0) {
      $('body').addClass('is-scrolled');
    } else {
      $('body').removeClass('is-scrolled');
    }
  }
  checkScroll();
  $(window).scroll(function () {
    checkScroll();
  });
  $('.toggler').click(function () {
    $('body').toggleClass('is-menu');
  });

  // $('.faq-item .faq-q').click(function () {
  //   $(this).parent().toggleClass('is-active');
  // });

  $('.faq-item .faq-q').click(function () {
    var $faqItem = $(this).parent();
    var $faqA = $faqItem.find('.faq-a');

    if ($faqItem.hasClass('is-active')) {
      // Slide up
      $faqA.css('max-height', $faqA[0].scrollHeight + 20 + 'px');
      setTimeout(function () {
        $faqA.css('max-height', 0);
      }, 10); // Small delay to start the transition
    } else {
      // Slide down
      $faqA.css('max-height', $faqA[0].scrollHeight + 20 + 'px');
    }

    $faqItem.toggleClass('is-active');
  });

  $(window).on('resize', function () {
    $('.faq-item.is-active .faq-a').each(function () {
      var $faqA = $(this);
      // Reset max-height based on new screen size
      $faqA.css('max-height', $faqA[0].scrollHeight + 20 + 'px');
    });
  });

  var gallerySwiper = new Swiper('.swiper--gallery', {
    slidesPerView: 3,
    spaceBetween: 40,
    loop: true,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      576: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1025: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
    },
    navigation: {
      prevEl: '.section-gallery .swiper-prev',
      nextEl: '.section-gallery .swiper-next',
    },
  });
}
